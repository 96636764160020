'use client'

import clsx from 'clsx'
import { useEffect, useState } from 'react'

export const ForbidenGenresBlock = () => {
  const [visibility, setVisibility] = useState(true)

  useEffect(() => {
    if (localStorage.getItem('isDisplayGenresBlock')) {
      setVisibility(false)
    }
  }, [])

  return (
    <div
      className={clsx(
        'grid relative px-4 py-1.5 pr-5 text-white dark:text-black-1000 rounded-md bg-primary grid-flow-row auto-rows-max mt-3 md:mt-5 max-w-7xl m-auto max-md:mx-1',
        {
          invisible: !visibility,
        },
      )}
    >
      На сайте отстуствуют следующие жанры: Сёнэн-ай/яой, юри, сёдзе-ай
      <span
        onClick={() => {
          localStorage.setItem('isDisplayGenresBlock', '1')
          setVisibility(false)
        }}
        className="absolute cursor-pointer hover hover:opacity-70 top-1.5 right-1 font-semibold"
      >
        x
      </span>
    </div>
  )
}
