'use client'

import { UserIcon } from '@heroicons/react/16/solid'
import { Book, BookStatistic } from '@prisma/client'
import clsx from 'clsx'
import Link from 'next/link'
import { memo } from 'react'
import {
  getBookImage,
  getBookTitle,
  getBookUrl,
  isBlur,
} from 'src/app/lib/utils/book.util'
import { Icon } from 'src/components/common/Icon'
import { BookTypeEnum } from 'src/enums'
import { t } from 'src/helpers/translate.helper'
import { BookImage } from '../../BookImage'

export type BookStatisticType = Omit<BookStatistic, 'rate'> & { rate: number }

export const SliderCard = memo(function SliderCard(props: {
  book: Book & {
    bookStatistic: BookStatisticType | null
  }
  isUserAuth: boolean
  priorityImage?: boolean
  disableAnimate: boolean
}) {
  const { book } = props

  const getColor = () => {
    if (!book.bookStatistic || book.bookStatistic.reviews < 10) {
      return ''
    }

    const rate = book.bookStatistic.rate

    if (rate >= 4.5) {
      return 'text-success'
    }

    if (rate < 4) {
      return 'text-danger'
    }

    return ''
  }

  return (
    <div
      key={book.id}
      className="grid min-w-[calc(theme(spacing.1)_*_36)] content-start pb-2 md:min-w-[calc(theme(spacing.1)_*_40)] gap-y-2.5 relative group"
    >
      <div className="h-[calc(theme(spacing.1)_*_54)] md:h-[calc(theme(spacing.1)_*_60)]">
        <BookImage
          fill
          isHoverEffect
          disableAnimate={props.disableAnimate}
          isBlur={isBlur(props.isUserAuth, book)}
          priority={props.priorityImage}
          sizes="(max-width: 768px) 144px, 160px"
          commonClassName="rounded-sm [&_>_div]:rounded-sm dark:bg-black-800 bg-gray-100"
          className="min-h-[calc(theme(spacing.1)_*_54)] max-h-[calc(theme(spacing.1)_*_54)] md:min-h-[calc(theme(spacing.1)_*_60)] md:max-h-[calc(theme(spacing.1)_*_60)]"
          alt={getBookTitle(book)}
          src={getBookImage(book, 'imageMid')}
        />
      </div>

      <div
        className={clsx(
          'grid gap-x-1.5 text-xs grid-flow-col justify-start items-center',
          getColor(),
        )}
      >
        <span className="font-medium line-clamp-1 text-gray-primary mr-2">
          {book.type ? t(BookTypeEnum.getLabels()[book.type]) : undefined}
        </span>

        <Icon name="star" className="w-4" />
        <span>{props.book.bookStatistic?.rate || 0.0}</span>
        <span className="text-gray-primary">|</span>
        <UserIcon className="w-4 text-gray-primary" />
        <span className="text-gray-primary mr-1">
          {props.book.bookStatistic?.reviews || 0}
        </span>
      </div>

      <Link
        href={getBookUrl(book.slug)}
        className="font-semibold group-hover:text-primary line-clamp-2 leading-4 px-1 text-tiny before:absolute before:inset-0"
      >
        {getBookTitle(book)}
      </Link>
    </div>
  )
})
